import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { StatisticsContext } from "@pages/Statistics/context";

import useResource from "@hooks/useResource";

import { getAdvancedPeriodStringParam, getTypeParam } from "@helpers/general";

import TitleRow from "@components/Common/TitleRow";
import DownloadDropdown from "@components/Common/DownloadDropdown";

import StatisticsTop10FamiliesCol from "./StatisticsTop10FamiliesCol";
import Top10FamiliesCol from "./Top10FamiliesCol";
import CustomSelectFilter from "@components/Common/CustomSelectFilter";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import ErrorPage from "@components/Common/ErrorPage";

export default function Top10FamiliesRow() {
    const { t } = useTranslation();
    const period = useSelector((state: any) => state.period);
    const type = useSelector((state: any) => state.type);
    const advancedDate = useSelector((state: any) => state.advancedDate);
    const selections = useSelector((state: any) => state.selections);

    const [percentageData, setPercentageData] = React.useState<any[]>([]);
    const [histogramData, setHistogramData] = React.useState<any[]>([]);
    const [categoriesData, setCategoriesData] = React.useState<any[]>([]);
    const [top10FamiliesSelection, setTop10FamiliesSelection] = React.useState<
        string
    >("");
    const [families, setFamilies] = React.useState<string[]>([
        t("Top 10 families"),
    ]);

    const {
        resourceData: familiesList,
        error: familiesListError,
    } = useResource(
        `${
            process.env.REACT_APP_API_V1_URL
        }/statistics/products/families-list?${getAdvancedPeriodStringParam(
            period,
            advancedDate
        )}${
            selections.designation && selections.designation !== "my_favorites"
                ? `&selected=${selections.designation}`
                : ""
        }${type === t("Average turnover") ? "" : `&type=${getTypeParam(type)}`}`
    );

    React.useEffect(() => {
        setFamilies([t("Top 10 families"), ...familiesList.data]);
    }, [familiesList, t]);

    return (
        <React.Fragment>
            <StatisticsContext.Provider
                value={{
                    period,
                    type,
                    advancedDate,
                    selections,
                    percentageData,
                    setPercentageData,
                    histogramData,
                    setHistogramData,
                    categoriesData,
                    setCategoriesData,
                    top10FamiliesSelection,
                    setTop10FamiliesSelection,
                }}
            >
                <TitleRow title={t("Articles per top 10 families")}>
                    {familiesListError ? (
                        <ErrorPage />
                    ) : (
                        <CustomErrorBoundary>
                            <CustomSelectFilter options={families} />
                        </CustomErrorBoundary>
                    )}
                    <DownloadDropdown
                        percentageData={percentageData!}
                        histogramData={histogramData!}
                        categories={categoriesData!}
                    />
                </TitleRow>

                <div
                    className="d-grid__clz 
                                grid-gap__clz 
                                grid-xl-statistics-products-details__clz 
                                grid-lg-statistics-template-details__clz 
                                grid-md-statistics-template-details__clz 
                                grid-sm-template-col__clz grid-xs-template-col__clz 
                                pr-0"
                >
                    <CustomErrorBoundary>
                        <Top10FamiliesCol />
                    </CustomErrorBoundary>
                    <CustomErrorBoundary>
                        <StatisticsTop10FamiliesCol />
                    </CustomErrorBoundary>
                </div>
            </StatisticsContext.Provider>
        </React.Fragment>
    );
}
