import { AnyAction } from "redux";
import { ADD_SELECTIONS } from "./actions-types";

const INIT_STATE: SelectionType = {
    designation: "",
    selection: [],
};

export default function selections(
    state = INIT_STATE,
    action: AnyAction
): SelectionType {
    if (action.type === ADD_SELECTIONS) {
        return action.payload;
    } else {
        return state;
    }
}
