const INIT_STATE: any = "";

export default function subFamiliesGroupDesignation(
    state = INIT_STATE,
    action: any
): any {
    if (action.type === "SUBFAMILIES_GROUP_DESIGNATION") {
        return action.payload;
    } else {
        return state;
    }
}
