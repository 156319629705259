import React from "react";
import { Link } from "react-router-dom";

export default function ConfigMenu() {
    return (
        <Link to="/my-selections" className="dropdown d-inline-block">
            <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle waves-effect mr-1"
            >
                <i className="bx bx-store bx-tada-hover"></i>
            </button>
        </Link>
    );
}
