import React from "react";
import { useDispatch } from "react-redux";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import NProgress from "nprogress";
import { useTranslation } from "react-i18next";

import { clearPeriod, changeType } from "@store/actions";

export default function LanguageDropdown() {
    let { pathname } = useLocation();
    const { i18n } = useTranslation();

    const [menu, setMenu] = React.useState<boolean>(false);
    const [language, setLanguage] = React.useState<string>(
        i18n.language === "fr" ? "Français" : "English"
    );
    const dispatch = useDispatch();

    let matchRegulationModesRoute: boolean = pathname === "/regulation-modes";
    let matchEmployeesRoutes: boolean = pathname.includes("employees");
    let matchStatisticsTurnoverRoute: boolean =
        pathname === "/statistics-turnover";

    function changeLanguage(currentLanguage: string) {
        i18n.changeLanguage(currentLanguage === "en" ? "en" : "fr");

        dispatch(clearPeriod());

        if (matchRegulationModesRoute || matchEmployeesRoutes) {
            dispatch(changeType(i18n.t("Turnover")));
        } else if (matchStatisticsTurnoverRoute) {
            dispatch(changeType(i18n.t("Incl-tax")));
        } else {
            dispatch(changeType(i18n.t("Turnover incl-tax")));
        }

        if (currentLanguage === "en") {
            setLanguage("English");
        } else if (currentLanguage === "fr") {
            setLanguage("Français");
        }
    }

    return (
        <div className="d-none">
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className=" d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item waves-effect"
                    tag="button"
                >
                    <span className="align-middle">{language}</span>
                </DropdownToggle>
                <DropdownMenu className="language-switch" right>
                    <DropdownItem
                        tag="a"
                        onClick={async () => {
                            NProgress.start();
                            await changeLanguage(
                                language === "English" ? "fr" : "en"
                            );
                            NProgress.done();
                        }}
                        className={`notify-item ${
                            language === "English" ? "active" : "none"
                        } ${language === "English" ? "d-none" : ""}`}
                    >
                        <span className="align-middle">English</span>
                    </DropdownItem>
                    <DropdownItem
                        tag="a"
                        onClick={() => changeLanguage("fr")}
                        className={`notify-item ${
                            language === "Français" ? "active" : "none"
                        } ${language === "Français" ? "d-none" : ""}`}
                    >
                        <span className="align-middle">Français</span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}
