import ReactGA from "react-ga";

const TRACKING_ID = process.env.REACT_APP_GA!;

function init() {
    ReactGA.initialize(TRACKING_ID);
}

function sendEvent(payload: any) {
    ReactGA.event(payload);
}

function sendPageview(path: any) {
    ReactGA.set({ page: path });
    ReactGA.pageview(path);
}

export { init, sendEvent, sendPageview };
