import React from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { uid } from "react-uid";
import { useSnapshot } from "valtio";

import MySelectionsContext from "../context";

import store, { setCheckedFranchises } from "@pages/MySelections/store";

import FavoriteButtonWrapper from "./FavoriteButtonWrapper";

import { capitalize, formatCurrency } from "@helpers/general";

type TableRowPropsType = {
    key: string;
    franchise: FranchisesDataType;
};

type CheckedStateType = {
    [key: string]: boolean;
};

export default function TableRow({ franchise }: TableRowPropsType) {
    const { t } = useTranslation();
    const {
        setExpandedRows,
        expandedRows,
        selectedSelectionValue,
    } = React.useContext(MySelectionsContext);

    const MySelectionsStore = useSnapshot(store);

    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [allShopsChecked, setAllShopsChecked] = React.useState<boolean>(false);
    const [checked, setChecked] = React.useState<CheckedStateType>({});
    const expanderBody = React.useRef(null);

    function getShopsIdInArray(shops: FranchisesDataType[] | undefined) {
        return shops ? shops.map((shop) => shop.id) : [];
    }

    function getFranchiseIdInArray(
        franchises: FranchisesDataType[] | undefined
    ) {
        return franchises ? franchises.map((franchise) => franchise.id) : [];
    }

    function handleShopCheckboxInputOnChangeEvent(
        event: React.ChangeEvent<HTMLInputElement>,
        shopId: string | number,
        franchiseId: string | number
    ) {
        const checkedInput = event.target;
        const selectionValue = `${franchiseId}|${shopId}`;

        setChecked((prevState: CheckedStateType) => {
            const newState = { ...prevState };
            newState[selectionValue] = !prevState[selectionValue];
            return newState;
        });

        updateFranchisesSelections(checkedInput.checked, selectionValue);
    }

    function handleFranchiseCheckboxInputOnChangeEvent(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const isChecked = event.target.checked;

        setAllShopsChecked(isChecked);
        setChecked((prevState: CheckedStateType) => {
            const newState = { ...prevState };
            for (const inputName in newState) {
                newState[inputName] = isChecked;
            }
            return newState;
        });

        updateFranchisesSelections(isChecked, checked);
    }

    function updateFranchisesSelections(
        isChecked: boolean,
        inputValue: string | object
    ) {
        let isSelected: boolean =
            MySelectionsStore.checkedFranchises.filter(function (
                selection: string
            ) {
                return typeof inputValue === "string"
                    ? inputValue === selection
                    : Object.keys(inputValue).includes(selection);
            }).length > 0;

        if (isChecked) {
            setCheckedFranchises!(
                isSelected
                    ? typeof inputValue === "string"
                        ? MySelectionsStore.checkedFranchises.filter(function (
                              selection: string
                          ) {
                              return inputValue !== selection;
                          })
                        : Array.from(
                              new Set(
                                  MySelectionsStore.checkedFranchises.concat(
                                      Object.keys(inputValue)
                                  )
                              )
                          )
                    : Array.from(
                          new Set(
                              MySelectionsStore.checkedFranchises.concat(
                                  typeof inputValue === "string"
                                      ? inputValue
                                      : Object.keys(inputValue)
                              )
                          )
                      )
            );
        } else {
            setCheckedFranchises(
                MySelectionsStore.checkedFranchises.filter(function (
                    selection: string
                ) {
                    return typeof inputValue === "string"
                        ? inputValue !== selection
                        : !Object.keys(inputValue).includes(selection);
                })
            );
        }
    }

    function handleSeeMoreButtonOnClickEvent() {
        let array: any[] = [];

        expandedRows!.forEach((el: any) => {
            if (
                el.id ===
                `${franchise.id}|${franchise
                    .shops!.map((shop: FranchisesDataType) => shop.id)
                    .join(",")}`
            ) {
                array.push({
                    id: el.id,
                    expanded: !el.expanded,
                });
            } else {
                array.push({
                    id: el.id,
                    expanded: false,
                });
            }
        });

        setExpandedRows!(array);
    }

    React.useEffect(() => {
        expandedRows!.forEach((el: any) => {
            if (
                el.id ===
                `${franchise.id}|${franchise
                    .shops!.map((shop: FranchisesDataType) => shop.id)
                    .join(",")}`
            ) {
                setExpanded(el.expanded);
            }
        });

        let allShopsChecked = true;

        for (const inputName in checked) {
            if (checked[inputName] === false) {
                allShopsChecked = false;
            }
        }

        setAllShopsChecked(allShopsChecked ? true : false);
    }, [checked, setExpandedRows, franchise, expandedRows]);

    React.useEffect(() => {
        let obj: { [key: string]: boolean } = {};

        if (franchise.shops) {
            getShopsIdInArray(franchise.shops!)!.forEach(
                (id: string | number) => {
                    obj[
                        `${franchise.id}|${id}`
                    ] = MySelectionsStore.checkedFranchises.includes(
                        `${franchise.id}|${id}`
                    );
                }
            );
        } else {
            getFranchiseIdInArray(MySelectionsStore.allFranchises)!.forEach(
                (id: string | number) => {
                    obj[id] = MySelectionsStore.checkedFranchises.includes(
                        `${franchise.id}|${id}`
                    );
                }
            );
        }
        setChecked(obj);
    }, [
        MySelectionsStore.allFranchises,
        franchise,
        MySelectionsStore.checkedFranchises,
    ]);

    return (
        <React.Fragment>
            <tr key={uid(franchise.id)}>
                {MySelectionsStore.mySavedSelectionSelectAction !== undefined &&
                MySelectionsStore.mySavedSelectionSelectAction.action !==
                    "clear" &&
                selectedSelectionValue?.length !== 0 &&
                selectedSelectionValue !== null ? (
                    <th></th>
                ) : (
                    <th scope="row">
                        <input
                            value={franchise.id}
                            type="checkbox"
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                                handleFranchiseCheckboxInputOnChangeEvent(event)
                            }
                            checked={allShopsChecked}
                        />
                    </th>
                )}
                <td>{franchise.name}</td>
                <td>{franchise.shop}</td>
                <td>{t(capitalize(franchise.kind))}</td>
                <td>{franchise.address}</td>
                <td>{franchise.zip}</td>
                <td>{franchise.city}</td>
                <td>{franchise.regions}</td>
                <td>{franchise.country}</td>
                <td>{formatCurrency(franchise.turnover)}</td>
                <td className="d-flex justify-content-center">
                    {franchise.shops && (
                        <Button
                            type="button"
                            color="secondary"
                            outline
                            className="w-md"
                            onClick={handleSeeMoreButtonOnClickEvent}
                            id={`franchise${franchise.id}_shops_details`}
                        >
                            {t(expanded ? "Hide details" : "Details")}
                        </Button>
                    )}
                </td>
            </tr>
            {expanded &&
                franchise.shops &&
                franchise.shops.map(function (shop: FranchisesDataType) {
                    return (
                        <tr ref={expanderBody} key={uid(shop.id)}>
                            <td></td>
                            {MySelectionsStore.mySavedSelectionSelectAction !==
                                undefined &&
                            MySelectionsStore.mySavedSelectionSelectAction
                                .action !== "clear" &&
                            selectedSelectionValue?.length !== 0 &&
                            selectedSelectionValue !== null ? (
                                <th></th>
                            ) : (
                                <th scope="row">
                                    <input
                                        value={shop.id}
                                        type="checkbox"
                                        onChange={(
                                            event: React.ChangeEvent<
                                                HTMLInputElement
                                            >
                                        ) =>
                                            handleShopCheckboxInputOnChangeEvent(
                                                event,
                                                shop.id,
                                                franchise.id
                                            )
                                        }
                                        checked={
                                            checked[
                                                `${franchise.id}|${shop.id}`
                                            ]
                                        }
                                    />
                                </th>
                            )}
                            <td>{shop.name}</td>
                            <td>{t(capitalize(shop.kind))}</td>
                            <td>{shop.address}</td>
                            <td>{shop.zip}</td>
                            <td>{shop.city}</td>
                            <td>{shop.regions}</td>
                            <td>{shop.country}</td>
                            <td>{formatCurrency(shop.turnover)}</td>
                            <td className="d-flex justify-content-center">
                                <FavoriteButtonWrapper
                                    franchise_id={franchise.id}
                                    favorite={shop}
                                />
                            </td>
                        </tr>
                    );
                })}
        </React.Fragment>
    );
}
