import React from "react";

import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

import Avatar from "react-avatar";

import { useTranslation } from "react-i18next";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { logoutUser } from "@store/actions";
import { socket } from "@socket";

const ProfileMenu = ({ user, logoutUser }: any) => {
    const { t } = useTranslation();
    const [menu, setMenu] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string>("");

    React.useEffect(() => {
        if (user) {
            setName(
                `${
                    user.firstname
                        ? `${user.firstname} ${user.lastname}`
                        : user.username
                }`
            );
        } else if (localStorage.getItem("authUser")) {
            if (
                process.env.REACT_APP_DEFAULTAUTH === "fake" ||
                process.env.REACT_APP_DEFAULTAUTH === "jwt"
            ) {
                const obj = JSON.parse(localStorage.getItem("authUser")!);
                setName(
                    `${
                        obj.firstname
                            ? `${obj.firstname} ${obj.lastname}`
                            : obj.username
                    }`
                );
            }
        }
    }, [user]);

    function logout(): void {
        socket.disconnect();

        logoutUser(null);
    }

    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <Avatar name={name} size="40" round />
                    <span className="d-none d-xl-inline-block ml-2 mr-1">
                        {name}
                    </span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </DropdownToggle>
                <DropdownMenu right>
                    <Link className="d-none dropdown-item" to="/profile">
                        <i className="bx bx-user font-size-16 align-middle mr-1"></i>
                        {t("Profile")}
                    </Link>
                    <DropdownItem className="d-none" tag="a" href="#">
                        <i className="bx bx-wrench font-size-17 align-middle mr-1"></i>
                        {t("Settings")}
                    </DropdownItem>
                    <DropdownItem
                        className="d-none"
                        tag="a"
                        href="auth-lock-screen"
                    >
                        <i className="bx bx-lock-open font-size-16 align-middle mr-1"></i>
                        {t("Lock screen")}
                    </DropdownItem>
                    <div className="d-none dropdown-divider"></div>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => logout()}
                        className="dropdown-item"
                    >
                        <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                        <span>{t("Logout")}</span>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

const mapStatetoProps = (state: any) => {
    const { user } = state.Login;
    return { user };
};

export default withRouter(
    connect(mapStatetoProps, { logoutUser })(ProfileMenu)
);
