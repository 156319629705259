import React from "react";
import { Modal, ModalBody, ModalFooter, Alert } from "reactstrap";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import {
    usePagination,
    useTable,
    useGlobalFilter,
    useRowSelect,
} from "react-table";
import BTable from "react-bootstrap/Table";
import swal from "@sweetalert/with-react";
import NProgress from "nprogress";
import { AvForm } from "availity-reactstrap-validation";
import { useSnapshot } from "valtio";
import {
    StyledButton,
    StyledIconButton,
    StyledSearchInput,
    TrashIcon,
    EditIcon,
    AngleSmallLeftIcon,
    StyledParagraph,
    StyledTextInput,
} from "@aureskonnect/react-ui";

import store, { setGroupedSubFamilies, setGroupName } from "./store";

import { SettingsSubFamiliesContext } from "./context";

import useResource from "@hooks/useResource";

import { ITEMS_PER_PAGE } from "@constants/index";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import ErrorPage from "@components/Common/ErrorPage";
import NoImagePlaceholderIcon from "../components/NoImagePlaceholderIcon";
import Pagination from "@components/Common/StatisticsPagination";

export default function EditStep({ go }: any) {
    const { t } = useTranslation();

    const { groupName } = useSnapshot(store);

    const { groups: subFamiliesGroups } = React.useContext(
        SettingsSubFamiliesContext
    );

    const newGroupDesignationRef = React.useRef<HTMLInputElement>(null);

    const [
        selectedSubFamiliesList,
        setSelectedSubFamiliesList,
    ] = React.useState<any[]>([]);
    const [newGroupDesignation, setNewGroupDesignation] = React.useState<
        string
    >(groupName);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

    const [isGroupNameEmpty, setIsGroupNameEmpty] = React.useState<boolean>(
        false
    );

    const [
        isGroupNameContainsSpecialStrings,
        setIsGroupNameContainsSpecialStrings,
    ] = React.useState<boolean>(false);

    const [
        isNameExistsInGroupsList,
        setIsNameExistsInGroupsList,
    ] = React.useState<boolean>(false);

    const { resourceData, error, mutate: mutateGroupDetails } = useResource(
        `${process.env.REACT_APP_API_V1_URL}/settings/sub-families/group-detail?group-name=${groupName}`
    );

    React.useEffect(() => {
        setSelectedSubFamiliesList(resourceData.data);
    }, [resourceData]);

    const handleRemoveSelectedSubFamily = React.useCallback(
        async (subFamily: any) => {
            swal({
                text: t("Are you sure you want to delete this group?"),
                icon: "warning",
                buttons: [t("Cancel"), t("Delete")],
                dangerMode: true,
            }).then(async (willDelete: boolean) => {
                if (willDelete) {
                    let localSelectedSubFamiliesList = selectedSubFamiliesList
                        .map((s: any) => `${s.sub_family}|${s.family}`)
                        .filter((item: string) => {
                            return !item.includes(
                                `${subFamily.sub_family}|${subFamily.family}`
                            );
                        });

                    let apiUrl = `${process.env.REACT_APP_API_V1_URL}/settings/sub-families/groups`;

                    try {
                        NProgress.start();

                        mutate(
                            apiUrl,
                            await fetch(apiUrl, {
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bareer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                body: JSON.stringify({
                                    designation: newGroupDesignation,
                                    groupment: localSelectedSubFamiliesList,
                                }),
                                method: "PUT",
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    NProgress.done();

                                    if (data.error) {
                                        throw Error(
                                            "Error while saving selection!"
                                        );
                                    }

                                    setSelectedSubFamiliesList(
                                        (prevState: any) => {
                                            let newState = prevState.filter(
                                                (item: any) => {
                                                    return (
                                                        `${item.sub_family}|${item.family}` !==
                                                        `${subFamily.sub_family}|${subFamily.family}`
                                                    );
                                                }
                                            );

                                            mutateGroupDetails({
                                                error: false,
                                                data: newState,
                                            });

                                            return newState;
                                        }
                                    );

                                    return swal({
                                        icon: "success",
                                        content: <p>{t(data.message)}!</p>,
                                        buttons: false,
                                        timer: 2000,
                                    });
                                })
                        );
                    } catch (e: any) {
                        NProgress.done();

                        return swal({
                            icon: "error",
                            content: <p>{t("There's an error")!}</p>,
                            buttons: false,
                            timer: 2000,
                        });
                    }
                }
            });
        },
        [t, newGroupDesignation, selectedSubFamiliesList, mutateGroupDetails]
    );

    const columns = React.useMemo(
        () => [
            {
                Header: t("Subfamily"),
                accessor: "sub_family",
            },
            {
                Header: t("Picture"),
                accessor: function (subFamily: any) {
                    return (
                        <div>
                            {subFamily.image === "" ? (
                                <NoImagePlaceholderIcon />
                            ) : (
                                subFamily.image
                            )}
                        </div>
                    );
                },
                disableGlobalFilter: true,
            },
            {
                Header: t("Family"),
                accessor: "family",
            },
            {
                Header: "",
                id: "actions",
                disableGlobalFilter: true,
                accessor: function (subFamily: any) {
                    return (
                        <div>
                            <StyledButton
                                outline
                                rounded
                                variant="secondary"
                                style={{
                                    float: "right",
                                }}
                                className="d-none d-sm-none d-md-block"
                                onClick={() =>
                                    handleRemoveSelectedSubFamily(subFamily)
                                }
                            >
                                {t("Delete")}
                            </StyledButton>
                            <StyledIconButton
                                className="d-block d-sm-block d-md-none m-0"
                                icon="TrashIcon"
                                onClick={() =>
                                    handleRemoveSelectedSubFamily(subFamily)
                                }
                            >
                                <TrashIcon height={20} width={20} />
                            </StyledIconButton>
                        </div>
                    );
                },
            },
        ],
        [t, handleRemoveSelectedSubFamily]
    );

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }: any, ref: any) => {
            const defaultRef = React.useRef();
            const resolvedRef = ref || defaultRef;

            React.useEffect(() => {
                resolvedRef.current!.indeterminate = indeterminate;
            }, [resolvedRef, indeterminate]);

            let localProps = { ...rest, title: t("Select line") };

            return <input type="checkbox" ref={resolvedRef} {...localProps} />;
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex },
        setGlobalFilter,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data: selectedSubFamiliesList,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <IndeterminateCheckbox
                            {...getToggleAllPageRowsSelectedProps()}
                        />
                    ),
                    Cell: ({ row }) => (
                        <IndeterminateCheckbox
                            {...row.getToggleRowSelectedProps()}
                        />
                    ),
                    disableGlobalFilter: true,
                },
                ...columns,
            ]);
        }
    );

    function handleNewGroupDesignationInputOnChangeEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        let specialStrings: string[] = [
            "\\",
            "+",
            "-",
            "&",
            "/",
            "*",
            "!",
            "?",
            "ç",
            "#",
            "~",
            "^",
            "@",
            '"',
            "]",
            "}",
            "#",
            "{",
            "[",
            "|",
            "-",
            "'",
        ];

        setIsGroupNameContainsSpecialStrings(
            specialStrings.filter((string: any) => {
                return e.target.value.includes(string);
            }).length > 0
        );

        setIsGroupNameEmpty(
            e.target.value.length === 0 || e.target.value.trim().length === 0
        );

        setIsNameExistsInGroupsList(
            subFamiliesGroups.filter((group: any) => group === e.target.value)
                .length > 0
        );
    }

    async function handleMultipleRemoveFamiliesButtonOnClickEvent() {
        swal({
            text: t("Are you sure you want to delete this group?"),
            icon: "warning",
            buttons: [t("Cancel"), t("Delete")],
            dangerMode: true,
        }).then(async (willDelete: boolean) => {
            if (willDelete) {
                let localSelectedSubFamilies: any[] = selectedFlatRows
                    .map((row: any) => row.original)
                    .map((s: any) => `${s.sub_family}|${s.family}`);

                let subFamilies: any[] = [];

                selectedSubFamiliesList.forEach((subFamily: any) => {
                    if (
                        !localSelectedSubFamilies.includes(
                            `${subFamily.sub_family}|${subFamily.family}`
                        )
                    ) {
                        subFamilies.push(
                            `${subFamily.sub_family}|${subFamily.family}`
                        );
                    }
                });

                let apiUrl = `${process.env.REACT_APP_API_V1_URL}/settings/sub-families/groups`;

                try {
                    mutate(
                        apiUrl,
                        await fetch(apiUrl, {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bareer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify({
                                designation: newGroupDesignation,
                                groupment: subFamilies,
                            }),
                            method: "PUT",
                        })
                            .then((response) => response.json())
                            .then(async (data) => {
                                if (data.error) {
                                    throw Error(
                                        "Error while saving selection!"
                                    );
                                }

                                setSelectedSubFamiliesList((prevState: any) => {
                                    let newState = prevState.filter(
                                        (item: any) => {
                                            return subFamilies.includes(
                                                `${item.sub_family}|${item.family}`
                                            );
                                        }
                                    );

                                    mutateGroupDetails({
                                        error: false,
                                        data: newState,
                                    });

                                    return newState;
                                });

                                return swal({
                                    icon: "success",
                                    content: <p>{t(data.message)}!</p>,
                                    buttons: false,
                                    timer: 2000,
                                });
                            })
                    );
                } catch (e: any) {
                    return swal({
                        icon: "error",
                        content: <p>{t("There's an error")!}</p>,
                        buttons: false,
                        timer: 2000,
                    });
                }
            }
        });
    }

    function handlePreviousStepAction() {
        go(0);
    }

    function toggleOpeningModal() {
        setIsModalOpened(!isModalOpened);
        setIsGroupNameEmpty(false);
        setIsGroupNameContainsSpecialStrings(false);
        setIsNameExistsInGroupsList(false);
    }

    React.useEffect(() => {
        setPageSize(ITEMS_PER_PAGE);
    }, [setPageSize]);

    async function handleValidateNewGroupDesignationButtonOnClickEvent() {
        if (newGroupDesignationRef.current!.value! !== "") {
            toggleOpeningModal();

            try {
                mutate(
                    `${process.env.REACT_APP_API_V1_URL}/settings/sub-families/edit-group-name`,
                    await fetch(
                        `${process.env.REACT_APP_API_V1_URL}/settings/sub-families/edit-group-name`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bareer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify({
                                "old-name": groupName,
                                "new-name": newGroupDesignationRef.current!
                                    .value!,
                            }),
                            method: "PUT",
                        }
                    )
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }

                            setNewGroupDesignation(
                                newGroupDesignationRef.current!.value!
                            );

                            setGroupName(
                                newGroupDesignationRef.current!.value!
                            );

                            return swal({
                                icon: "success",
                                content: (
                                    <p>{t("group updated successfully")}!</p>
                                ),
                            });
                        })
                );
            } catch (e: any) {
                return swal({
                    icon: "error",
                    content: <p>{t("There's an error")!}</p>,
                });
            }
        } else {
            setIsGroupNameEmpty(true);
        }
    }

    function handleAddingNewSubFamliesToSubFamiliesGroup() {
        setGroupedSubFamilies(selectedSubFamiliesList);
        go(4);
    }

    return (
        <React.Fragment>
            {error ? (
                <ErrorPage />
            ) : (
                <CustomErrorBoundary>
                    <div className="d-md-flex justify-content-md-between align-items-md-center">
                        <div className="d-md-flex align-items-md-center mt-2 mt-md-2 mb-2">
                            <div className="d-flex align-items-center">
                                <StyledIconButton
                                    icon="AngleSmallLeftIcon"
                                    className="m-0 p-0"
                                    onClick={handlePreviousStepAction}
                                >
                                    <AngleSmallLeftIcon
                                        height={35}
                                        width={35}
                                    />
                                </StyledIconButton>
                                <span className="mx-2 mb-0 font-size-14 white-space-nowrap h5">
                                    {newGroupDesignation}
                                </span>
                                <StyledIconButton
                                    icon="EditIcon"
                                    className="m-0"
                                    onClick={toggleOpeningModal}
                                >
                                    <EditIcon height={20} width={20} />
                                </StyledIconButton>
                            </div>
                            <span
                                className="divider mt-0 mx-4 d-none d-md-block"
                                style={{
                                    backgroundColor: "#808080a6",
                                    height: "2.25rem",
                                    width: "2px",
                                }}
                            />
                            <StyledSearchInput
                                onChange={(e) => {
                                    setGlobalFilter(e.target.value);
                                }}
                                placeholder={`${t("Search")} ...`}
                                className="p-0 mx-2 mx-md-0 mt-2 mt-md-0"
                            />
                        </div>
                        <div className="d-md-flex mt-2 mx-2 mb-2">
                            <StyledButton
                                rounded
                                variant="secondary"
                                className="mr-2 mb-2 mb-md-0 w-xs-100 w-sm-100 w-md-unset white-space-nowrap"
                                disabled={selectedFlatRows.length === 0}
                                onClick={
                                    handleMultipleRemoveFamiliesButtonOnClickEvent
                                }
                            >
                                {t("Delete sub-families")}
                            </StyledButton>
                            <StyledButton
                                rounded
                                variant="secondary"
                                className="mr-2 w-xs-100 w-sm-100 w-md-unset white-space-nowrap"
                                onClick={
                                    handleAddingNewSubFamliesToSubFamiliesGroup
                                }
                            >
                                {t("Add sub-families")}
                            </StyledButton>
                        </div>
                    </div>
                    {page.length > 0 ? (
                        <React.Fragment>
                            <BTable responsive {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => (
                                                    <th
                                                        className="white-space-nowrap"
                                                        {...column.getHeaderProps()}
                                                    >
                                                        {column.render(
                                                            "Header"
                                                        )}
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <td
                                                            className="white-space-nowrap align-middle"
                                                            {...cell.getCellProps()}
                                                        >
                                                            {cell.render(
                                                                "Cell"
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </BTable>
                            {selectedSubFamiliesList.length > ITEMS_PER_PAGE ? (
                                <Pagination
                                    canPreviousPage={canPreviousPage}
                                    canNextPage={canNextPage}
                                    pageOptions={pageOptions}
                                    pageCount={pageCount}
                                    gotoPage={gotoPage}
                                    nextPage={nextPage}
                                    previousPage={previousPage}
                                    pageIndex={pageIndex}
                                />
                            ) : null}
                        </React.Fragment>
                    ) : null}
                    <Modal isOpen={isModalOpened} centered>
                        <ModalBody className="justify-content-center">
                            <StyledParagraph className="text-uppercase font-weight-bold text-center mt-4">
                                {t("Family groups")}
                            </StyledParagraph>
                            {isGroupNameEmpty ? (
                                <Alert className="mx-4" color="danger">
                                    {t("Please enter the name of the group")}
                                </Alert>
                            ) : null}
                            {isGroupNameContainsSpecialStrings ? (
                                <Alert className="mx-4 mt-2" color="danger">
                                    {t(
                                        "The name should not contain any special characters"
                                    )}
                                </Alert>
                            ) : null}
                            {isNameExistsInGroupsList ? (
                                <Alert className="mx-4 mt-2" color="danger">
                                    {t("This group name is used before")}
                                </Alert>
                            ) : null}
                            <AvForm>
                                <StyledTextInput
                                    id="sub-families-group-designation"
                                    name="sub-families-group-designation"
                                    value={newGroupDesignation}
                                    placeholder={t("Enter the designation")}
                                    type="text"
                                    className="mt-5 ml-4"
                                    style={{ width: "90%" }}
                                    onChange={
                                        handleNewGroupDesignationInputOnChangeEvent
                                    }
                                />
                            </AvForm>
                        </ModalBody>
                        <ModalFooter className="justify-content-center mt-3">
                            <StyledButton
                                variant="primary"
                                outline
                                rounded
                                onClick={toggleOpeningModal}
                                className="w-25"
                            >
                                {t("Return")}
                            </StyledButton>
                            <StyledButton
                                variant="primary"
                                outline={false}
                                rounded
                                disabled={
                                    isGroupNameContainsSpecialStrings ||
                                    isNameExistsInGroupsList ||
                                    isGroupNameEmpty
                                }
                                onClick={
                                    handleValidateNewGroupDesignationButtonOnClickEvent
                                }
                                className="w-25"
                            >
                                {t("Validate")}
                            </StyledButton>
                        </ModalFooter>
                    </Modal>
                </CustomErrorBoundary>
            )}
        </React.Fragment>
    );
}
