import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";

import {
    changeSidebarTheme,
    changeSidebarType,
    changeTopbarTheme,
    changeLayoutWidth,
} from "@store/actions";

import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Rightbar from "@components/CommonForBoth/Rightbar";

type PropsType = {
    layoutWidth: "fluid";
    leftSideBarTheme: "dark";
    leftSideBarType: "default";
    topbarTheme: "light";
    isPreloader: false;
    showRightSidebar: false;
    changeSidebarTheme: typeof changeSidebarTheme;
    changeLayoutWidth: typeof changeLayoutWidth;
    changeSidebarType: typeof changeSidebarType;
    changeTopbarTheme: typeof changeTopbarTheme;
} & ChildrenPropsType;

export default function Layout(props: PropsType) {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const {
        layoutWidth,
        leftSideBarTheme,
        leftSideBarType,
        showRightSidebar,
        topbarTheme,
        isPreloader,
    } = useSelector((state: any) => state.Layout);

    React.useEffect(() => {
        if (isPreloader) {
            document.getElementById("preloader")!.style.display = "block";

            document.getElementById("status")!.style.display = "block";

            setTimeout(function () {
                document.getElementById("preloader")!.style.display = "none";

                document.getElementById("status")!.style.display = "none";
            }, 2500);
        } else {
            document.getElementById("preloader")!.style.display = "none";

            document.getElementById("status")!.style.display = "none";
        }

        // Scroll Top to 0
        window.scrollTo(0, 0);

        if (leftSideBarTheme) {
            dispatch(changeSidebarTheme(leftSideBarTheme as any));
        }

        if (layoutWidth) {
            dispatch(changeLayoutWidth(layoutWidth as any));
        }

        if (leftSideBarType) {
            dispatch(changeSidebarType(leftSideBarType, isMobile));
        }

        if (topbarTheme) {
            dispatch(changeTopbarTheme(topbarTheme));
        }
    }, [
        dispatch,
        leftSideBarType,
        topbarTheme,
        layoutWidth,
        isPreloader,
        leftSideBarTheme,
    ]);

    function toggleMenuCallback() {
        if (leftSideBarType === "default") {
            dispatch(changeSidebarType("condensed", isMobile));
        } else if (leftSideBarType === "condensed") {
            dispatch(changeSidebarType("default", isMobile));
        }
    }

    return (
        <React.Fragment>
            <div id="preloader">
                <div id="status">
                    <div className="spinner-chase">
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                    </div>
                </div>
            </div>

            <div id="layout-wrapper">
                <div className={pathname === "/home" ? "d-none" : ""}>
                    <Header toggleMenuCallback={toggleMenuCallback} />
                </div>
                <Sidebar type={leftSideBarType} />

                <div
                    className={
                        pathname === "/my-selections" ||
                        pathname === "/my-selection-list" ||
                        pathname === "/home"
                            ? "ml-0 main-content"
                            : "main-content"
                    }
                    style={{ minHeight: "calc(100vh - 67.5px)" }}
                >
                    {props.children}
                </div>
                <Footer />
            </div>
            {showRightSidebar ? <Rightbar /> : null}
        </React.Fragment>
    );
}
