import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";

import { capitalize, formatCurrency, formatExcelData } from "@helpers/general";

import ExportCsvToExcel from "../ExportCsvToExcel";

export default function DownloadDropdown({
    percentageData,
    histogramData,
    categories,
    filename,
}: {
    percentageData: any[];
    histogramData: any[];
    categories: any[];
    filename?: string;
}) {
    const { t } = useTranslation();

    const period = useSelector((state: any) => state.period);
    const type = useSelector((state: any) => state.type);
    const advancedDate = useSelector((state: any) => state.advancedDate);

    const [isDropdownOpened, setIsDropdownOpened] = React.useState<boolean>(
        false
    );
    const [csvData, setCsvData] = React.useState<any[][]>([]);
    const [excelData, setExcelData] = React.useState<
        {
            columns: any[];
            data: any[];
        }[]
    >([]);
    const [, setHeaders] = React.useState<any[]>();

    React.useEffect(() => {
        let array: any[] = [];

        let headers: any[] = [t("Categories")];

        histogramData?.forEach((datum: any) => {
            headers.push(t(capitalize(datum.designation.toLowerCase())));
        });

        let averageLine: any[] = [];

        categories.forEach((category: any) => {
            let localArray: any[] = [];
            let localCategory = category;

            histogramData?.forEach((item: any, index: number) => {
                item.value.forEach((datum: any) => {
                    let localDesignation = String(datum.designation);

                    if (
                        advancedDate.startDate !== "" &&
                        advancedDate.endDate !== ""
                    ) {
                        let daysBetweenDates = moment
                            .unix(Number(advancedDate.endDate))
                            .diff(
                                moment.unix(Number(advancedDate.startDate)),
                                "days"
                            );
                        if (daysBetweenDates === 0) {
                            localDesignation = String(datum.designation);
                        } else if (daysBetweenDates <= 31) {
                            localDesignation = capitalize(
                                moment
                                    .unix(Number(datum.designation))
                                    .format("ddd DD-MM-YYYY")
                            );
                        } else {
                            localDesignation = capitalize(
                                moment
                                    .unix(Number(datum.designation))
                                    .format("MMM YYYY")
                            );
                        }
                    } else if (
                        [
                            t("Current week"),
                            t("The last 7 days"),
                            t("The last 14 days"),
                            t("The last 30 days"),
                        ].includes(period)
                    ) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("ddd DD-MM-YYYY")
                        );
                        localCategory = capitalize(
                            moment
                                .unix(Number(category))
                                .format("ddd DD-MM-YYYY")
                        );
                    } else if (period === t("Current month")) {
                        localDesignation = capitalize(
                            moment.unix(Number(datum.designation)).format("DD")
                        );
                    } else if (period === t("Current year")) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("MMM YYYY")
                        );
                    } else if (
                        [
                            t("The last 90 days"),
                            t("The last 180 days"),
                            t("The last 365 days"),
                        ].includes(period)
                    ) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("MMM YYYY")
                        );
                    }

                    if (localDesignation === localCategory) {
                        localArray.push(
                            type !== t("Tickets")
                                ? formatCurrency(datum.value)
                                : `${datum.value} ${t("Tickets")}`
                        );
                    }
                });
                if (!localArray[index]) {
                    localArray[index] =
                        type !== t("Tickets") ? formatCurrency(0) : 0;
                }
            });

            array.push([localCategory, ...localArray]);
        });

        percentageData?.forEach((datum: any) => {
            averageLine.push(
                type !== t("Tickets")
                    ? formatCurrency(datum / array.length)
                    : (datum / array.length).toFixed(2)
            );
        });

        setHeaders(headers);

        let formattedPercentageData = percentageData.map((element: any) => {
            return type !== t("Tickets") ? formatCurrency(element) : element;
        });

        setCsvData([headers, ...array, [t("Total"), ...percentageData]]);

        setExcelData([
            formatExcelData(
                headers,
                array,
                formattedPercentageData,
                averageLine,
                type
            ),
        ]);
    }, [
        histogramData,
        advancedDate,
        categories,
        percentageData,
        period,
        type,
        t,
    ]);

    return (
        <Dropdown
            isOpen={isDropdownOpened}
            tag="div"
            toggle={() =>
                setIsDropdownOpened((prevState: boolean) => !prevState)
            }
            className="p-1"
        >
            <DropdownToggle className="d-flex">
                <i className="bx bx-menu font-size-20"></i>
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem>
                    <CSVLink
                        className="btn p-0"
                        data={csvData}
                        filename={`${
                            filename ? filename : t("Statistics")
                        }.csv`}
                    >
                        {t("Download in CSV format")}
                    </CSVLink>
                </DropdownItem>
                <DropdownItem>
                    <ExportCsvToExcel
                        data={excelData}
                        filename={filename ? filename : t("Statistics")}
                    >
                        <div className="btn p-0">
                            {t("Download in excel format")}
                        </div>
                    </ExportCsvToExcel>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}
