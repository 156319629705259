import i18n from "i18next";
import { AnyAction } from "redux";

import { capitalize } from "@helpers/general";

import {
    ADD_REGULATION_MODE,
    CLEAR_REGULATION_MODES,
    EMPTY,
    INIT_REGULATION_MODES,
    SELECT_REGULATION_MODES,
} from "./actions-types";

const INIT_STATE: string[] = [];

export default function regulationModes(
    state = INIT_STATE,
    action: AnyAction
): string[] {
    switch (action.type) {
        case ADD_REGULATION_MODE:
            let newState: string[] = [...state];
            newState.push(action.payload);

            return newState;
        case SELECT_REGULATION_MODES:
            let localState: string[] = [...state];
            let storedRegulationModes = JSON.parse(
                localStorage.getItem("regulationModes")!
            );

            localState = localState.filter((el: string) => el !== EMPTY);

            if (
                localState
                    .map((el: string) => i18n.t(capitalize(el)))
                    .includes(action.payload)
            ) {
                if (action.payload === i18n.t("All regulation methods")) {
                    localState = [];
                } else if (
                    action.payload === i18n.t("Physical Payment Method")
                ) {
                    localState = localState.filter((element: string) => {
                        return ![
                            i18n.t("Coin acceptor"),
                            i18n.t("Cash box"),
                            i18n.t("Restaurant coupon"),
                            i18n.t("Check"),
                            i18n.t("Physical Payment Method"),
                            i18n.t("All regulation methods"),
                        ].includes(i18n.t(capitalize(element)));
                    });
                } else if (
                    action.payload === i18n.t("Digital Payment Method")
                ) {
                    localState = localState.filter(
                        (element: string) =>
                            ![
                                i18n.t("Bank card"),
                                i18n.t("Softapay"),
                                i18n.t("Fidelity"),
                                i18n.t("All regulation methods"),
                                i18n.t("Digital Payment Method"),
                            ].includes(i18n.t(capitalize(element)))
                    );
                } else {
                    let selectedRegulationModes = storedRegulationModes.filter(
                        (el: string) =>
                            i18n.t(capitalize(el)) === action.payload
                    )[0];

                    let isPhysicalchildrenSelected = [
                        i18n.t("Coin acceptor"),
                        i18n.t("Cash box"),
                        i18n.t("Restaurant coupon"),
                        i18n.t("Check"),
                    ].includes(selectedRegulationModes);

                    let isDigitalChildrenSelected = [
                        i18n.t("Bank card"),
                        i18n.t("Softapay"),
                        i18n.t("Fidelity"),
                    ].includes(selectedRegulationModes);

                    localState = localState
                        .filter(
                            (element: string) =>
                                i18n.t(capitalize(element)) !== action.payload
                        )
                        .filter(
                            (element: string) =>
                                i18n.t(capitalize(element)) !==
                                i18n.t("All regulation methods")
                        );

                    if (isPhysicalchildrenSelected) {
                        localState = localState.filter(
                            (el: string) =>
                                i18n.t(capitalize(el)) !==
                                i18n.t("Physical Payment Method")
                        );
                    }

                    if (isDigitalChildrenSelected) {
                        localState = localState.filter(
                            (el: string) =>
                                i18n.t(capitalize(el)) !==
                                i18n.t("Digital Payment Method")
                        );
                    }
                }
            } else if (action.payload === i18n.t("All regulation methods")) {
                localState = storedRegulationModes;
            } else if (action.payload === i18n.t("Physical Payment Method")) {
                localState = [
                    ...localState,
                    ...storedRegulationModes.filter((item: string) => {
                        return [
                            i18n.t("Coin acceptor"),
                            i18n.t("Cash box"),
                            i18n.t("Restaurant coupon"),
                            i18n.t("Check"),
                            i18n.t("Physical Payment Method"),
                        ].includes(i18n.t(capitalize(item)));
                    }),
                ];
            } else if (action.payload === i18n.t("Digital Payment Method")) {
                localState = [
                    ...localState,
                    ...storedRegulationModes.filter((item: string) =>
                        [
                            i18n.t("Bank card"),
                            i18n.t("Softapay"),
                            i18n.t("Fidelity"),
                            i18n.t("Digital Payment Method"),
                        ].includes(i18n.t(capitalize(item)))
                    ),
                ];
            } else {
                let selectedSalesSupport = storedRegulationModes.filter(
                    (el: string) => i18n.t(capitalize(el)) === action.payload
                )[0];

                localState.push(selectedSalesSupport);

                let physicalPaymentMethodChildren = storedRegulationModes
                    .map((el: string) => i18n.t(capitalize(el)))
                    .filter((el: string) =>
                        [
                            i18n.t("Coin acceptor"),
                            i18n.t("Cash box"),
                            i18n.t("Restaurant coupon"),
                            i18n.t("Check"),
                        ].includes(el)
                    );

                let isPhysicalPaymentMethodChildrenSelected =
                    localState.filter((element: string) =>
                        [
                            i18n.t("Coin acceptor"),
                            i18n.t("Cash box"),
                            i18n.t("Restaurant coupon"),
                            i18n.t("Check"),
                        ].includes(i18n.t(capitalize(element)))
                    ).length >= physicalPaymentMethodChildren.length;

                let digitalPaymentMethodCollectChildren = storedRegulationModes
                    .map((el: string) => i18n.t(capitalize(el)))
                    .filter((el: string) =>
                        [
                            i18n.t("Bank card"),
                            i18n.t("Softapay"),
                            i18n.t("Fidelity"),
                        ].includes(el)
                    );

                let isDigitalPaymentMethodCollectChildrenSelected =
                    localState.filter((element: string) =>
                        [
                            i18n.t("Bank card"),
                            i18n.t("Softapay"),
                            i18n.t("Fidelity"),
                        ].includes(i18n.t(capitalize(element)))
                    ).length >= digitalPaymentMethodCollectChildren.length;

                if (
                    isPhysicalPaymentMethodChildrenSelected &&
                    !localState.includes("Physical Payment Method")
                ) {
                    localState.push("Physical Payment Method");
                }

                if (
                    isDigitalPaymentMethodCollectChildrenSelected &&
                    !localState.includes("Digital Payment Method")
                ) {
                    localState.push("Digital Payment Method");
                }
            }

            if (localState.length === storedRegulationModes.length - 1) {
                localState.push("All regulation methods");
            }

            if (localState.length === 0) {
                localState.push(EMPTY);
            }

            return localState;
        case CLEAR_REGULATION_MODES:
            return [];
        case INIT_REGULATION_MODES:
            let newlocalState: string[] = action.payload;
            if (newlocalState.length === 0) {
                newlocalState.push(EMPTY);
            }
            return newlocalState;
        default:
            return state;
    }
}
