import { GROUP_FAMILIES, REMOVE_FAMILY, RESET_FAMILIES } from "./actions-types";

const INIT_STATE: any[] = [];

export default function familiesGroup(state = INIT_STATE, action: any): any {
    switch (action.type) {
        case GROUP_FAMILIES:
            return action.payload;
        case RESET_FAMILIES:
            return action.payload;
        case REMOVE_FAMILY:
            return state.filter(function (item: any) {
                return item !== action.payload;
            });
        default:
            return state;
    }
}
