import { proxy } from "valtio";

type StatisticsStoreType = {
    salesModeCards: CardsModesType;
    salesSupportCards: CardsModesType;
    regulationModesCards: CardsModesType;
};

const store = proxy<StatisticsStoreType>({
    salesModeCards: {
        modes: [],
        amounts: [],
    },
    salesSupportCards: {
        modes: [],
        amounts: [],
    },
    regulationModesCards: {
        modes: [],
        amounts: [],
    },
});

export function setSalesModeCards(salesModeCards: CardsModesType) {
    store.salesModeCards = salesModeCards;
}

export function setSalesSupportCards(salesSupportCards: CardsModesType) {
    store.salesSupportCards = salesSupportCards;
}

export function setRegulationModesCards(regulationModesCards: CardsModesType) {
    store.regulationModesCards = regulationModesCards;
}

export default store;
