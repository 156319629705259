import { AnyAction } from "redux";

import {
    ADD_REGULATION,
    REMOVE_REGULATION,
    RESET_REGULATION,
} from "./actions-types";

const INIT_STATE: any[] = [];

export default function regulations(
    state = INIT_STATE,
    action: AnyAction
): any[] {
    switch (action.type) {
        case ADD_REGULATION:
            return Array.from(new Set(state.concat(action.payload)));
        case REMOVE_REGULATION:
            return state.filter(function (item: string) {
                return item !== action.payload;
            });
        case RESET_REGULATION:
            return action.payload;
        default:
            return state;
    }
}
