import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { StatisticsContext } from "@pages/Statistics/context";

import TitleRow from "@components/Common/TitleRow";
import DownloadDropdown from "@components/Common/DownloadDropdown";
import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import DeliveredOrdersCol from "./DeliveredOrdersCol";
import StatisticsDeliveredOrdersCol from "./StatisticsDeliveredOrdersCol";

export default function DeliveredOrdersRow() {
    const { t } = useTranslation();

    const period = useSelector((state: any) => state.period);
    const type = useSelector((state: any) => state.type);
    const advancedDate = useSelector((state: any) => state.advancedDate);
    const selections = useSelector((state: any) => state.selections);

    const [percentageData, setPercentageData] = React.useState<any[]>([]);
    const [histogramData, setHistogramData] = React.useState<any[]>([]);
    const [categoriesData, setCategoriesData] = React.useState<any[]>([]);

    return (
        <StatisticsContext.Provider
            value={{
                period,
                type,
                advancedDate,
                selections,
                percentageData,
                setPercentageData,
                histogramData,
                setHistogramData,
                categoriesData,
                setCategoriesData,
            }}
        >
            <TitleRow title={t("Top 10 delivery men per delivered orders")}>
                <DownloadDropdown
                    percentageData={percentageData!}
                    histogramData={histogramData!}
                    categories={categoriesData!}
                />
            </TitleRow>
            <div
                className="d-grid__clz 
                        grid-gap__clz 
                        grid-xl-statistics-families-and-products-details__clz 
                        grid-lg-statistics-template-details__clz 
                        grid-md-statistics-template-details__clz 
                        grid-sm-template-col__clz 
                        grid-xs-template-col__clz 
                        pr-0"
            >
                <CustomErrorBoundary>
                    <DeliveredOrdersCol />
                </CustomErrorBoundary>
                <CustomErrorBoundary>
                    <StatisticsDeliveredOrdersCol />
                </CustomErrorBoundary>
            </div>
        </StatisticsContext.Provider>
    );
}
