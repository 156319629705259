import { AnyAction } from "redux";
import i18n from "i18next";

import { SET_REGULATION_TYPE } from "./actions-types";

const INIT_STATE: string = i18n.t("All regulation methods");

export default function regulationType(
    state = INIT_STATE,
    action: AnyAction
): string {
    if (action.type === SET_REGULATION_TYPE) {
        return action.payload;
    } else {
        return state;
    }
}
