import { GROUP_ARTICLES, RESET_ARTICLES } from "./actions-types";

const INIT_STATE: any[] = [];

export default function articlesGroup(state = INIT_STATE, action: any): any {
    switch (action.type) {
        case GROUP_ARTICLES:
            return action.payload;
        case RESET_ARTICLES:
            return action.payload;
        default:
            return state;
    }
}
