import { ADVANCED_DATE } from "./actions-types";

type ChangeDateActionType = {
    type: any;
    payload: any;
};

type InitStateType = {
    startDate: string;
    endDate: string;
};

const INIT_STATE: InitStateType = { startDate: "", endDate: "" };

export default function advancedDate(
    state = INIT_STATE,
    action: ChangeDateActionType
): {
    startDate: string;
    endDate: string;
} {
    if (action.type === ADVANCED_DATE) {
        return action.payload;
    } else {
        return state;
    }
}
