import { AnyAction } from "redux";

import {
    CHANGE_LAYOUT,
    CHANGE_LAYOUT_WIDTH,
    CHANGE_SIDEBAR_THEME,
    CHANGE_SIDEBAR_TYPE,
    CHANGE_TOPBAR_THEME,
    SHOW_RIGHT_SIDEBAR,
    SHOW_SIDEBAR,
    CHANGE_PRELOADER,
    TOGGLE_LEFTMENU,
} from "./actionTypes";

export const changeLayout = (layout: AnyAction): AnyAction => ({
    type: CHANGE_LAYOUT,
    payload: layout,
});

export const changePreloader = (layout: AnyAction): AnyAction => ({
    type: CHANGE_PRELOADER,
    payload: layout,
});

export const changeLayoutWidth = (width: AnyAction): AnyAction => ({
    type: CHANGE_LAYOUT_WIDTH,
    payload: width,
});

export const changeSidebarTheme = (theme: AnyAction): AnyAction => ({
    type: CHANGE_SIDEBAR_THEME,
    payload: theme,
});

export const changeSidebarType = (
    sidebarType: string,
    isMobile: boolean = false
): AnyAction => {
    return {
        type: CHANGE_SIDEBAR_TYPE,
        payload: { sidebarType, isMobile },
    };
};

export const changeTopbarTheme = (topbarTheme: string): AnyAction => ({
    type: CHANGE_TOPBAR_THEME,
    payload: topbarTheme,
});

export const showRightSidebarAction = (isopen: AnyAction): AnyAction => ({
    type: SHOW_RIGHT_SIDEBAR,
    payload: isopen,
});

export const showSidebar = (isopen: AnyAction): AnyAction => ({
    type: SHOW_SIDEBAR,
    payload: isopen,
});

export const toggleLeftmenu = (isopen: AnyAction): AnyAction => ({
    type: TOGGLE_LEFTMENU,
    payload: isopen,
});
