import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { StatisticsContext } from "@pages/Statistics/context";

import CustomErrorBoundary from "@components/Common/CustomErrorBoundary";
import TitleRow from "@components/Common/TitleRow";
import DownloadDropdown from "@components/Common/DownloadDropdown";

import StatisticsTop10FamiliesCol from "./StatisticsTop10FamiliesCol";
import Top10FamiliesCol from "./Top10FamiliesCol";

export default function Top10FamiliesRow() {
    const { t } = useTranslation();

    const period = useSelector((state: any) => state.period);
    const type = useSelector((state: any) => state.type);
    const advancedDate = useSelector((state: any) => state.advancedDate);
    const selections = useSelector((state: any) => state.selections);
    const [percentageData, setPercentageData] = React.useState<any[]>([]);
    const [histogramData, setHistogramData] = React.useState<any[]>([]);
    const [categoriesData, setCategoriesData] = React.useState<any[]>([]);

    return (
        <React.Fragment>
            <StatisticsContext.Provider
                value={{
                    period,
                    type,
                    advancedDate,
                    selections,
                    percentageData,
                    setPercentageData,
                    histogramData,
                    setHistogramData,
                    categoriesData,
                    setCategoriesData,
                }}
            >
                <TitleRow title={t("Top 10 families")}>
                    <DownloadDropdown
                        percentageData={percentageData}
                        histogramData={histogramData}
                        categories={categoriesData}
                    />
                </TitleRow>
                <div className="d-grid__clz grid-gap__clz grid-xl-statistics-template-col__clz grid-lg-statistics-template-col__clz grid-statistics-template-col__clz grid-sm-template-col__clz grid-xs-template-col__clz">
                    <CustomErrorBoundary>
                        <Top10FamiliesCol />
                    </CustomErrorBoundary>
                    <CustomErrorBoundary>
                        <StatisticsTop10FamiliesCol />
                    </CustomErrorBoundary>
                </div>
            </StatisticsContext.Provider>
        </React.Fragment>
    );
}
