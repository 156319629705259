import { FAMILIES_GROUP_DESIGNATION } from "./actions-types";

const INIT_STATE: any = "";

export default function familiesGroupDesignation(
    state = INIT_STATE,
    action: any
): any {
    if (action.type === FAMILIES_GROUP_DESIGNATION) {
        return action.payload;
    } else {
        return state;
    }
}
