import React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

import { toggleLeftmenu, changeSidebarType } from "@store/actions";

import SidebarLogo from "./SidebarLogo";
import ConfigMenu from "@components/CommonForBoth/TopbarDropdown/ConfigMenu";
import ProfileMenu from "@components/CommonForBoth/TopbarDropdown/ProfileMenu";
import LanguageDropdown from "@components/CommonForBoth/TopbarDropdown/LanguageDropdown";

function Header({
    leftMenu,
    leftSideBarType,
    changeSidebarType,
    toggleLeftmenu,
}: any) {
    const { t } = useTranslation();
    let { pathname } = useLocation();
    const [search, setSearch] = React.useState<boolean>(false);
    const isMobile: boolean = /iPhone|iPad|iPod|Android/i.test(
        navigator.userAgent
    );

    let matchSelectionRoute: boolean =
        pathname === "/my-selections" || pathname === "/my-selection-list";

    function tToggle(): void {
        toggleLeftmenu(!leftMenu);
        if (leftSideBarType === "default") {
            changeSidebarType("condensed", isMobile);
        } else if (leftSideBarType === "condensed") {
            changeSidebarType("default", isMobile);
        }
    }

    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <Link to="/dashboard" className="logo logo-light">
                                <SidebarLogo type={leftSideBarType} />
                            </Link>
                        </div>
                        <button
                            type="button"
                            onClick={() => {
                                tToggle();
                            }}
                            className={`${classNames({
                                "d-none": matchSelectionRoute,
                            })} btn btn-sm px-3 font-size-16 header-item waves-effect`}
                            id="vertical-menu-btn"
                        >
                            <i className="fa fa-fw fa-bars"></i>
                        </button>

                        <form className="app-search d-none">
                            <div className="position-relative">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={`${t("Search")} ...`}
                                />
                                <span className="bx bx-search-alt"></span>
                            </div>
                        </form>
                    </div>
                    <div className="d-flex">
                        <div className="dropdown d-none ml-2">
                            <button
                                onClick={() => {
                                    setSearch(!search);
                                }}
                                type="button"
                                className="btn header-item noti-icon waves-effect"
                                id="page-header-search-dropdown"
                            >
                                <i className="mdi mdi-magnify"></i>
                            </button>
                            <div
                                className={
                                    search
                                        ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                                        : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                                }
                                aria-labelledby="page-header-search-dropdown"
                            >
                                <form className="p-3">
                                    <div className="form-group m-0">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search ..."
                                                aria-label="Recipient's username"
                                            />
                                            <div className="input-group-append">
                                                <button
                                                    className="btn btn-primary"
                                                    type="submit"
                                                >
                                                    <i className="mdi mdi-magnify"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <LanguageDropdown />
                        <ProfileMenu />
                        <ConfigMenu />
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
}

const mapStatetoProps = (state: AnyAction) => {
    const { layoutType, leftMenu, leftSideBarType } = state.Layout;
    return { layoutType, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
    toggleLeftmenu,
    changeSidebarType,
})(Header);
